<template>
    <div class="price--block">
        <div class="icon">
            <img :src="require('@/assets/img/icons/' + icon + '.svg')" />
        </div>
        <div class="text">
            <span class="title">{{ title }}</span>
            <span class="price">{{ price | currency }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String,
        price: Number
    },
    filters: {
        currency(val) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(val);
        }
    }
}
</script>