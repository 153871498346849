<template>
    
    <page page="prijzen-en-beschikbaarheid" theme="light" type="page">

        <div class="container">
            <div class="row section">
                <div class="col-md-6">
                    <h3>Algemene informatie</h3>
                    <p>
                        Prijzen zijn <strong>inclusief</strong> WiFi, linnengoed en handdoeken, bedden opmaakservice, eindschoonmaak, toeristenbelasting.
                    </p>
                    <p>
                        Het is een NIET-rokers accommodatie en er zijn maximaal 2 huisdieren toegestaan.
                    </p>
                    <p>
                      Inchecktijden tussen 15.00 uur - 18.00 uur<br>
                      Uitchecktijden tussen 8.00 uur - 11.00 uur
                    </p>
                </div>
                <div class="col-md-6">
                    <h3>Op aanvraag</h3>
                    <p>
                        <ul class="checklist">
                            <li>Bijplaatsen kinderbed, kinderstoel en kinderservies.</li>
                            <li>Prijzen voor langere verblijfsduur</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>

        <div id="prices">
            <div class="container">
                <div class="row section">
                    <div class="col-md-5">
                        <h3>Prijzen</h3>
                        <p>
                            Alle prijzen zijn inclusief een lekker ontbijt.
                        </p>
                        <price-block icon="user" title="Per nacht 1 volwassene" :price="110.00"></price-block>
                        <price-block icon="user-friends" title="Per nacht 2 volwassenen" :price="125.00"></price-block>
                        <price-block icon="user-plus" title="Toeslag per nacht per persoon" :price="35.00"></price-block>
                        <price-block icon="dog" title="Toeslag per nacht per hond" :price="10.00"></price-block>
                        <!--<price-block icon="child" title="Toeslag per nacht per kind t/m 12 jaar" :price="17.50"></price-block>-->
                    </div>
                    <div class="col-md-6 col-md-offset-1">
                        <h3>Uw prijs berekenen</h3>
                        <p>
                            U kunt via het onderstaande formulier uw prijs berekenen.
                        </p>
                        <div class="price--calculator">
                            <div class="form-group">
                                <label for="">Aantal personen</label>
                                <div class="counter">
                                    <a href="#" @click.prevent="decrease('person')"><img src="@/assets/img/icons/minus.svg"/></a>
                                    <span>{{ person }}</span>
                                    <a href="#" @click.prevent="increase('person')"><img src="@/assets/img/icons/plus.svg"/></a>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Aantal honden</label>
                                <div class="counter">
                                    <a href="#" @click.prevent="decrease('dog')"><img src="@/assets/img/icons/minus.svg"/></a>
                                    <span>{{ dog }}</span>
                                    <a href="#" @click.prevent="increase('dog')"><img src="@/assets/img/icons/plus.svg"/></a>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Aantal nachten</label>
                                <div class="counter">
                                    <a href="#" @click.prevent="decrease('night')"><img src="@/assets/img/icons/minus.svg"/></a>
                                    <span>{{ night }}</span>
                                    <a href="#" @click.prevent="increase('night')"><img src="@/assets/img/icons/plus.svg"/></a>
                                </div>
                            </div>
                            <!--<div class="price--block" v-if="night > 6">
                                <div class="icon">
                                    <img :src="require('@/assets/img/icons/tree-alt.svg')" />
                                </div>
                                <div class="text">
                                    <span class="title">Inclusief gratis rondvaart door de Biesboch</span><br/>
                                    Deze ontvangt u bij een reservering vanaf 7 dagen
                                </div>
                            </div>-->
                        </div>
                        
                    </div>
                </div>
            </div>
            <transition name="bounce-up" mode="out-in">
                <div class="total--price" v-show="total > 0">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-6">
                                <h3>Totale prijs</h3>
                                {{ total | currency }}
                            </div>
                            <div class="col-xs-6 text-right">
                                <router-link to="/reserveren" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/calendar-check.svg" />Nu reserveren</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <image-row :images="['appels.jpg', 'kasteel-voorkant.jpg', 'sloot-3.jpg']" prefix="omgeving/small/"></image-row>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
import ImageRow from '../modules/AppImageRow.vue';
import PriceBlock from '../modules/AppPriceBlock.vue';

export default {
    metaInfo: {
        title: 'Prijzen & beschikbaarheid',
        meta: [
            { vmid: 'description', name: 'description', content: 'Prijzen zijn inclusief WiFi, linnengoed en handdoeken, bedden opmaakservice, eindschoonmaak, toeristenbelasting en huisdieren. Het is een NIET-rokers accommodatie en er zijn maximaal 2 huisdieren toegestaan.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/prijzen-en-beschikbaarheid'}
        ]
    },
    data() {
        return {
            person: 1,
            dog: 0,
            night: 1,
            total: 0
        }
    },
    methods: {
        increase(type) {
            switch (type) {
                case 'person':
                    if (this.person + this.child == 4) break;
                    this.person++;
                break;
                case 'dog':
                    if (this.dog == 2) break;
                    this.dog++;
                break;
                case 'night':
                    this.night++;
                break;
            }
            this.updateCart();
        },
        decrease(type) {
            switch (type) {
                case 'person':
                    if (this.person < 2) break;
                    this.person--;
                break;
                case 'dog':
                    if (this.dog < 1) break;
                    this.dog--;
                break;
                case 'night':
                    if (this.night < 2) break;
                    this.night--;
                break;
            }
            this.updateCart();
        },
        updateCart() {
            var sum = 110.00;

            if (this.person > 1) sum += 15;
            if (this.person > 2) sum += 35;
            if (this.person > 3) sum += 35;

            if (this.dog > 0) sum += this.dog * 10.00;

            this.total = sum * this.night;
        }
    },
    components: {
        Page,
        ImageRow,
        PriceBlock
    },
    filters: {
        currency(val) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(val);
        }
    }
}
</script>
